<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="500px"
    transition="dialog-transition"
    :persistent="dirty"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        color="success"
        small fab
        class="mr-1"
      >
        <v-icon>fas fa-award</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="color1 color1Text--text">
        <v-toolbar-title>Award Bids</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="color2 color2Text--text"
          small fab
          @click.stop="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <loading-bar :value="!!loading.length || bidLoading"></loading-bar>
      <v-card-text class="pt-3">
        <v-window v-model="window" touchless>
          <v-window-item
            v-for="w in windows"
            :key="w.key"
          >
            <template v-if="w.type === 'programSelect'">
              <p class="title">Which tournament are you awarding bids to?</p>
              <v-select
                label="Bid Tournament"
                :item-text="(item) => `${item.tournament}${item.name ? ' ' + item.name : ''}`"
                :items="user.bidPrograms"
                return-object
                v-model="bidProgram"
              ></v-select>
            </template>

            <template v-if="w.type === 'summary'">
              <p class="title">{{bidProgram && bidProgram.tournament}}<br>Bid Summary</p>
              <v-list three-line>
                <v-list-item v-for="d in summary" :key="d.id" @click="goto(d.id)">
                  <v-list-item-content>
                    <v-list-item-title>{{d.name}}</v-list-item-title>
                    <v-list-item-subtitle>{{d.bids.length}} Bids Issued</v-list-item-subtitle>
                    <v-list-item-subtitle>{{d.trix.length}} Bids Trickled</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </template>

            <template v-if="w.type === 'division'">
              <p class="title">{{w.division.name}}</p>
              <v-data-table
                :headers="headers"
                :items="w.division.activeTeams"
                :options.sync="options"
                dense
              >
                <template v-slot:[`item.name`]="{item}">
                  {{item.name}}
                  <v-tooltip right v-if="existingBids && existingBids[item.id]">
                    <span>{{item.firstNames | formatArray}} already earned a bid at {{ existingBids[item.id].earnedAt }} for {{existingBids[item.id].earnedFor}}</span>
                    <template v-slot:activator="{on}">
                      <v-icon
                        v-on="on"
                        small
                        color="warning"
                      >fas fa-exclamation-circle</v-icon>
                    </template>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.iFinish`]="{item}">
                  {{item.iFinish | ordinal}}
                </template>
                <template v-slot:[`item.bid`]="{item}">
                  <v-checkbox
                    v-model="bidArray"
                    :value="item.id"
                    color="color3"
                    class="shrink ma-0 pa-0"
                    hide-details
                    readonly
                    @click.stop="toggle(item.id)"
                  ></v-checkbox>
                </template>
                <template v-slot:[`item.trickle`]="{item}">
                  <v-checkbox
                    v-if="bidArray.includes(item.id)"
                    v-model="trixArray"
                    :value="item.id"
                    color="color3"
                    class="shrink ma-0 pa-0"
                    hide-details
                    readonly
                    @click.stop="trickle(item.id)"
                  ></v-checkbox>
                </template>
              </v-data-table>
            </template>
          </v-window-item>
        </v-window>
        <v-snackbar
          v-model="snack"
          vertical absolute
          color="success white--text"
        >
          The bids have been saved
          <v-btn text color="white" @click.native="snack = false">Close</v-btn>
        </v-snackbar>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-fab-transition>
          <v-btn
            v-if="prevWindow"
            color="color3Text color3--text"
            small fab
            @click="window--"
            :disabled="saving"
          >
            <v-icon>fas fa-caret-left</v-icon>
          </v-btn>
        </v-fab-transition>
        <v-spacer></v-spacer>
        <v-fab-transition>
          <v-btn
            v-if="dirty"
            color="success white--text"
            small fab
            @click="save"
            :loading="!!loading.length"
            :disabled="currentWindow.invalid"
          >
            <v-icon>fas fa-save</v-icon>
          </v-btn>
        </v-fab-transition>
        <v-fab-transition>
          <v-btn
            v-if="nextWindow"
            color="color3 color3Text--text"
            small fab
            @click="window++"
            :disabled="!!loading.length || currentWindow.invalid"
          >
            <v-icon>fas fa-caret-right</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {

  data () {
    return {
      dialog: false,
      bidProgram: null,
      model: null,
      saving: false,
      window: 0,
      loading: [],
      headers: [
        { text: 'Finish', value: 'iFinish', align: 'center' },
        { text: 'Team', value: 'name', align: 'left' },
        { text: 'Bid', value: 'bid', align: 'left' },
        { text: 'Trickle', value: 'trickle', align: 'left' }
      ],
      options: { itemsPerPage: 10, sortBy: ['iFinish'] },
      bidModel: null,
      existingBids: null,
      snack: false
    }
  },
  computed: {
    ...mapGetters(['tournament', 'user']),
    dto () {
      return this.dirty && this.bidModel.filter(f => {
        if (!f.id) return true
        var og = this.bids.find(b => b.id === f.id)
        return !og || og.deleted !== f.deleted || og.trickle !== f.trickle
      })
    },
    windows () {
      let i = 0
      return [
        {
          key: i++,
          type: 'programSelect',
          invalid: !this.bidProgram
        },
        {
          key: i++,
          type: 'summary'
        },
        ...this.tournament.publicDivisions.map(m => {
          return {
            key: i++,
            type: 'division',
            division: m
          }
        })
      ]
    },
    currentWindow () {
      return this.windows.find(f => f.key === this.window)
    },
    currentD () {
      return this.currentWindow && this.currentWindow.division && this.currentWindow.division.name
    },
    nextWindow () {
      return this.windows.find(f => f.key === this.window + 1)
    },
    prevWindow () {
      return this.windows.find(f => f.key === this.window - 1)
    },
    bidLoading () {
      return this.tournament && this.tournament.bidStatus === 'loading'
    },
    bids () {
      const all = this.tournament && this.tournament.bids
      return this.bidProgram ? all.filter(f => f.bidProgramId === this.bidProgram.bidProgramId) : all
    },
    summary () {
      const bpId = this.bidProgram && this.bidProgram.bidProgramId
      return bpId && this.tournament.publicDivisions.map(d => {
        const teamIds = d.teams.map(t => t.id)
        return {
          id: d.id,
          name: d.name,
          bids: this.tournament.bids.filter(f => f.bidProgramId === bpId && teamIds.includes(f.teamId) && !f.trickle),
          trix: this.tournament.bids.filter(f => f.bidProgramId === bpId && teamIds.includes(f.teamId) && f.trickle)
        }
      })
    },
    bidArray () {
      return this.bidModel.filter(f => !f.deleted).map(m => m.teamId)
    },
    trixArray () {
      return this.bidModel.filter(f => f.trickle).map(m => m.teamId)
    },
    dirty () {
      return JSON.stringify(this.bids) !== JSON.stringify(this.bidModel)
    },
    summaryWindow () {
      return this.windows.find(f => f.type === 'summary')
    }
  },
  methods: {
    save () {
      if (!this.dto) return
      this.loading.push('saving')
      this.$VBL.tournament.postBids(this.tournament.id, this.dto)
        .then(r => {
          this.tournament.bids = r.data
          this.createModel()
          this.window = this.summaryWindow.key
          this.snack = true
        })
        .catch(e => {
          console.log(e.response)
        })
        .finally(() => { this.loading = this.loading.filter(f => f !== 'saving') })
    },
    goto (divisionId) {
      const w = this.windows.find(f => f.division && f.division.id === divisionId)
      if (w) {
        this.window = w.key
      }
    },
    createModel () {
      this.bidModel = JSON.parse(JSON.stringify(this.bids))
    },
    toggle (id) {
      const bid = this.bidModel.find(f => f.teamId === id)
      if (bid) {
        bid.deleted = !bid.deleted
        if (!bid.id && bid.deleted) {
          this.bidModel = this.bidModel.filter(f => f.teamId !== id)
        }
      } else {
        this.bidModel.push({
          bidProgramId: this.bidProgram.bidProgramId,
          teamId: id,
          trickle: false,
          deleted: false
        })
      }
    },
    trickle (id) {
      const bid = this.bidModel.find(f => f.teamId === id)
      if (bid) {
        bid.trickle = !bid.trickle
      }
    },
    checkTrickle () {
      this.loading.push('trickle')
      this.$VBL.tournament.trickleCheck(this.tournament.id, this.bidProgram.bidProgramId)
        .then(r => {
          this.existingBids = r.data
        })
        .catch(e => { console.log(e.response) })
        .finally(() => {
          this.loading = this.loading.filter(f => f !== 'trickle')
        })
    }
  },
  watch: {
    dialog: function (v) {
      if (v) {
        this.bidProgramId = this.user.bidPrograms.length > 1 ? null : this.user.bidPrograms[0].bidProgramId
        this.tournament.getBids()
      } else {
        this.window = 0
        this.bidProgram = null
      }
    },
    bidProgram: function (n, o) {
      if (n) {
        this.createModel()
        this.checkTrickle()
        this.window++
      }
    }
  }
}
</script>

<style scoped>

</style>
